import React from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"
import {graphql, useStaticQuery} from "gatsby"
import {useTranslation} from "react-i18next";
import BRANDS from "../../brands";

const NotFoundPage = ({location}) => {
  const data = useStaticQuery(graphql`
    query errorPageClientsSu {
      site {
        siteMetadata {
          countries {
            en {
              hotLine {
                tel
                title
              }
            }
          }
        }
      }
    }
  `)

  const {t} = useTranslation()

  return (
    <>
      <Head
        lang="by"
        title={t('head.404.title', { brand: BRANDS.ALLSPORTS.brand })}
        slug="/404/"
      />
      <Layout
        hotLine={data.site.siteMetadata.countries.en.hotLine}
        location={location}
        country="ru"
      >
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    </>
  )
}

export default NotFoundPage
